import wx from 'weixin-js-sdk'

/**
 * 返回当前的运行环境 开发dev、日常daily、预发pre、线上publish
 */
export function env() {
  return process.env.VUE_APP_BUILD_ENV
}

/**
 * 是否在微信环境
 */
export function isWxBrowser() {
  const u = window.navigator.userAgent.toLowerCase()
  return (/micromessenger/i.test(u))
}

/**
 * 是否在微信小程序中
 */
export const isMiniprogram = call => {
  return new Promise(resolve => {
    wx.miniProgram.getEnv(res => {
      resolve(res.miniprogram)
      if (window.__isMiniprogram === undefined) {
        window.__isMiniprogram = res.miniprogram
      }
      call && call(res.miniprogram, wx)
    }, rea => {
      call && call(rea.miniprogram, wx)
    })
  })
}

/**
 * 是否在ios环境
 */
export function isIosBrowser() {
  const u = window.navigator.userAgent.toLowerCase()
  return /iphone|ipad|ipod/i.test(u)
}

/**
 * 是否在android环境
 */
export function isAndroidBrowser() {
  const u = window.navigator.userAgent.toLowerCase()
  return (/android/i.test(u))
}
